<template>
    <div>

        <b-card no-body>
            <b-card-header>
                <h5 class="mb-0 text-white"><b>FILTROS</b></h5>
            </b-card-header>
            <b-card-body footer-tag="footer" class="pt-2">

                <b-row>
                    
                    <b-col cols="12" md="4" class="mb-2">
                        <label for="year"><strong>AÑO</strong></label>
                        <v-select
                            id="year"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            v-model="filters.year"
                            :options="options.years"
                            :clearable="false"
                            class="w-100"
                        />
                    </b-col>

                    <b-col cols="12" md="4" class="mb-2">
                        <label for="month"><strong>MES</strong></label>
                        <v-select
                            id="month"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            v-model="filters.month"
                            :options="options.months"
                            :clearable="false"
                            class="w-100"
                        />
                    </b-col>

                    <b-col cols="12" md="4" class="mb-2">
                        <label for="business_area"><strong>RUBRO</strong></label>
                        <v-select
                            id="business_area"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            v-model="filters.businessAreas"
                            :options="options.businessAreas"
                            class="w-100"
                            multiple
                        />
                    </b-col>

                    <b-col cols="12">
                        <label for="providers"><strong>PROVEEDOR(ES)</strong></label>
                        <v-select
                            id="providers"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            v-model="filters.providers"
                            :options="options.providers"
                            class="w-100"
                            multiple
                        />
                    </b-col>

                </b-row>

            </b-card-body>

            <template #footer>

                <!-- BUTTON SUBMIT -->
                <b-button-loading 
                    text="FILTRAR"
                    :processing="processing"
                    :block="true"
                    @process-action="getReportData">
                </b-button-loading>

            </template>

        </b-card>

        <b-card class="mb-0">

            <BButtonDownloadExcel 
                :data="downloadExcelData()" 
                :name="getExcelFileName()" 
            />

            <b-table
                ref="table"
                striped
                hover
                responsive
                class="position-relative text-center table-guarantee-funds mb-0"
                :per-page="perPage"
                :current-page="currentPage"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                show-empty
                :busy="processing"
                empty-text="NO SE ENCONTRARON REGISTROS COINCIDENTES"
            >

                <template #table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle mb-1"></b-spinner>
                        <div><b>CARGANDO</b></div>
                    </div>
                </template>

                <template #head(month)="data">
					<span v-html="data.label"></span>
				</template>

                <template #head(state)="data">
					<span v-html="data.label"></span>
				</template>

                <template #head(condition)="data">
					<span v-html="data.label"></span>
				</template>

                <template #head(initial_sentinel_risk)="data">
					<span v-html="data.label"></span>
				</template>

                <template #head(final_sentinel_risk)="data">
					<span v-html="data.label"></span>
				</template>

                <template #head(requested_amount)="data">
					<span v-html="data.label"></span>
				</template>

                <template #head(amount_approved)="data">
					<span v-html="data.label"></span>
				</template>

                <template #cell(month)="data">
                    <b-badge variant="light-info">{{ data.item.month }}</b-badge>
                </template>

                <template #cell(state)="data">
                    <b-badge :variant="data.item.state_color">
                        {{ data.item.state_name }}
                    </b-badge>
                </template>

                <template #cell(condition)="data">
                    <b-badge :variant="data.item.condition_color">
                        {{ data.item.condition_name }}
                    </b-badge>
                </template>

                <template #cell(percent)="data">
                    <span>{{ data.item.percent === null ? '-' : data.item.percent + '%' }}</span>
                </template>

                <template #cell(initial_sentinel_risk)="data">
                    <template v-if="data.item.initial_sentinel_risk_name">         
                        <b-badge :variant="data.item.initial_sentinel_risk_color">
                            {{ data.item.initial_sentinel_risk_name }}
                        </b-badge>	
                    </template>
                    <span v-else>
                        -
                    </span>
                </template>

                <template #cell(final_sentinel_risk)="data">
                    <template v-if="data.item.final_sentinel_risk_name">         
                        <b-badge :variant="data.item.final_sentinel_risk_color">
                            {{ data.item.final_sentinel_risk_name }}
                        </b-badge>	
                    </template>
                    <span v-else>
                        -
                    </span>
                </template>

                <template #cell(spreadsheet)="data">
                    <span>{{ getCurrencyFormat(data.item.spreadsheet) }}</span>
                </template>

                <template #cell(cts)="data">
                    <span>{{ getCurrencyFormat(data.item.cts) }}</span>
                </template>

                <template #cell(gratification)="data">
                    <span>{{ getCurrencyFormat(data.item.gratification) }}</span>
                </template>

                <template #cell(third_parties)="data">
                    <span>{{ getCurrencyFormat(data.item.third_parties) }}</span>
                </template>

                <template #cell(total)="data">
                    <span>{{ getCurrencyFormat(data.item.total) }}</span>
                </template>

                <template #cell(posted)="data">
                    <span>{{ getCurrencyFormat(data.item.posted) }}</span>
                </template>

                <template #cell(devolution)="data">
                    <span :class="devolutionColor(data.item.devolution)">{{ getCurrencyFormat(data.item.devolution) }}</span>
                </template>

                <template #cell(balance)="data">
                    <span>{{ getCurrencyFormat(data.item.balance) }}</span>
                </template>

                <template #cell(requested_amount)="data">
                    <span>{{ getCurrencyFormat(data.item.requested_amount) }}</span>
                </template>

                <template #cell(amount_approved)="data">
                    <span>{{ getCurrencyFormat(data.item.amount_approved) }}</span>
                </template>

                <template #cell(comments)="data">
                    <BButtonComments 
                        :comments="data.item.comments" 
                        :identifier="data.item.id"
                        :title="getTitleButtonCommentsModal(data.item)" 
                        @update-comments="updateComments($event)" 
                    />
                </template>

            </b-table>

        </b-card>

    </div>
</template>

<script>

    import BButtonDownloadExcel from '@core/components/b-button-download-excel/BButtonDownloadExcel.vue';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import BButtonComments from '@core/components/b-button-comments/BButtonComments.vue';
    import BButtonLoading from '@core/components/b-button-loading/BButtonLoading.vue';
    import { ref, onBeforeMount } from '@vue/composition-api';
    import { useToast } from 'vue-toastification/composition';
    import vSelect from 'vue-select';
    import moment from 'moment';
    import axios from '@axios';

    export default {
        components: {
            BButtonDownloadExcel,
            BButtonComments,
            BButtonLoading,

            vSelect
        },
        setup() {

            // USE TOAST
            const toast = useToast();

            // REFS
            const processing = ref(false);
            const options = ref({});

            const filters = ref({
                year: null,
                month: null,
                businessAreas: [],
                providers: []
            });

            const perPage = ref(50000);
            const totalRows = ref(1);
            const currentPage = ref(1);

            const sortBy = ref('');
            const sortDesc = ref(false);
            const sortDirection = ref('asc');
            const filter = ref(null);
            const filterOn = ref([]);
            const fields = ref(
                [
                    {
                        key: 'month',
                        label: 'MES/AÑO <br> EVALUACIÓN',
                        sortable: false
                    },
                    {
                        key: 'state',
                        label: 'ESTADO <br> FORMULARIO',
                        sortable: false
                    },
                    {
                        key: 'condition',
                        label: 'CONDICIÓN <br> FORMULARIO',
                        sortable: false
                    },
                    {
                        key: 'proveedor',
                        label: 'PROVEEDOR',
                        sortable: false
                    },
                    {
                        key: 'rubro',
                        label: 'RUBRO',
                        sortable: false
                    },
                    {
                        key: 'ruc',
                        label: 'RUC',
                        sortable: false
                    },
                    {
                        key: 'percent',
                        label: '%',
                        sortable: false
                    },
                    {
                        key: 'initial_sentinel_risk',
                        label: 'RIESGO SENTINEL <br> (INICIAL)',
                        sortable: false
                    },
                    {
                        key: 'final_sentinel_risk',
                        label: 'RIESGO SENTINEL <br> (FINAL)',
                        sortable: false
                    },
                    {
                        key: 'spreadsheet',
                        label: 'PLANILLA',
                        sortable: false
                    },
                    {
                        key: 'cts',
                        label: 'CTS',
                        sortable: false
                    },
                    {
                        key: 'gratification',
                        label: 'GRATIFICACIÓN',
                        sortable: false
                    },
                    {
                        key: 'third_parties',
                        label: 'TERCEROS',
                        sortable: false
                    },
                    {
                        key: 'total',
                        label: 'TOTAL',
                        sortable: false
                    },
                    {
                        key: 'posted',
                        label: 'CONTABILIZADO',
                        sortable: false
                    },
                    {
                        key: 'devolution',
                        label: 'DEVOLUCIÓN',
                        sortable: false
                    },
                    {
                        key: 'balance',
                        label: 'SALDO',
                        sortable: false
                    },
                    {
                        key: 'requested_amount',
                        label: 'MONTO <br> SOLICITADO',
                        sortable: false
                    },
                    {
                        key: 'amount_approved',
                        label: 'MONTO <br> APROBADO',
                        sortable: false
                    },
                    {
                        key: 'comments',
                        label: 'COMENTARIOS',
                        sortable: false
                    }
                ]
            );
            const items = ref([]);

            // CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
            onBeforeMount( async () => {
                await getSelectFilters();
                await setData();
                await getReportData();
            });

            const getSelectFilters = async () => {
                const { data } = await axios.get('/reports/guarantee-fund/filters');
                options.value = data.data;
            };

            const setData = async () => {

                const currentYear = parseInt(moment().subtract(1, 'months').format('YYYY'));
                const currentMonth = parseInt(moment().subtract(1, 'months').format('MM'));

                filters.value.year = options.value.years.find(year => year.id === currentYear);
                filters.value.month = options.value.months.find(month => month.id === currentMonth);

            };

            const getReportData = async () => {

                processing.value = true;

                const year = filters.value.year.id;
                const month = filters.value.month.id;

                const businessAreas = filters.value.businessAreas ? filters.value.businessAreas.map(item => item.value) : [];
                const providers = filters.value.providers ? filters.value.providers.map(item => item.value) : [];

                const { data } = await axios.get(`/reports/guarantee-fund?year=${year}&month=${month}&businessAreas=${businessAreas.join(',')}&providers=${providers.join(',')}`);

                items.value = data.data;
                perPage.value = data.data.length;
                totalRows.value = data.data.length;

                processing.value = false;

            };

            const onFiltered = (filteredItems) => {
                totalRows.value = filteredItems.length;
                currentPage.value = 1;
            };

            const getCurrencyFormat = (amount) => (amount ? `S/. ${parseFloat(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` : '-');

            const getTitleButtonCommentsModal = (data) => (`COMENTARIOS ${data.month} (${data.proveedor})`);
        
            const devolutionColor = (devolution) => {
                return devolution ? ( devolution.includes('-') ? 'text-danger' : 'text-success' ) : null;
            };

            const updateComments = async (payload) => {

                const { data } = await axios.put('/reports/guarantee-fund/update-comments', payload);

                await getReportData();

                toast({
                    component: ToastificationContent,
                    props: {
                        title: data.message,
                        icon: 'CheckIcon',
                        variant: 'success'
                    }
                });

            };

            const getExcelFileName = () => {
                let fileName = 'fondo_garantia.xls';

                if (filters.value.month && filters.value.year)
                {
                    fileName = `fondo_garantia_${filters.value.month.label.toLowerCase()}_${filters.value.year.label}.xls`;
                }
                
                return fileName;
            };

            const downloadExcelData = () => {

                let data = [];

                for (let a = 0; a < items.value.length; a++)
                {
                    let object = {
                        'ITEM': (a + 1),

                        'MES/AÑO EVALUACIÓN': items.value[a]['month'],
                        'ESTADO FORMULARIO': items.value[a]['state_name'],
                        'CONDICION FORMULARIO': items.value[a]['condition_name'],
                        'PROVEEDOR': items.value[a]['proveedor'],
                        'RUBRO': items.value[a]['rubro'],
                        'RUC': items.value[a]['ruc'],

                        'FONDO DE GARANTIA (%)': items.value[a]['percent'],
                        
                        'RIESGO SENTINEL (INICIAL)': items.value[a]['initial_sentinel_risk_name'],
                        'RIESGO SENTINEL (FINAL)': items.value[a]['final_sentinel_risk_name'],

                        'PLANILLA': getCurrencyFormat(items.value[a]['spreadsheet']),
                        'CTS': getCurrencyFormat(items.value[a]['cts']),
                        'GRATIFICACION': getCurrencyFormat(items.value[a]['gratification']),
                        'TERCEROS': getCurrencyFormat(items.value[a]['third_parties']),
                        'TOTAL': getCurrencyFormat(items.value[a]['total']),

                        'CONTABILIZADO': getCurrencyFormat(items.value[a]['posted']),
                        'DEVOLUCIÓN': getCurrencyFormat(items.value[a]['devolution']),
                        'SALDO': getCurrencyFormat(items.value[a]['balance']),

                        'MONTO SOLICITADO': getCurrencyFormat(items.value[a]['requested_amount']),
                        'MONTO APROBADO': getCurrencyFormat(items.value[a]['amount_approved']),

                        'COMENTARIOS': items.value[a]['comments']
                    };

                    data.push(object);
                }

                return data;

            };

            return {
                // TABLE DATA
                perPage,
                totalRows,
                currentPage,

                sortBy,
                sortDesc,
                sortDirection,
                filter,
                filterOn,
                fields,
                items,

                // DATA
                processing,
                filters,
                options,

                // METHODS
                getReportData,
                onFiltered,
                getCurrencyFormat,
                getTitleButtonCommentsModal,
                devolutionColor,
                updateComments,
                getExcelFileName,
                downloadExcelData
            }
        }
    }

</script>

<style lang="scss" scoped>

    .card-header {
        background-color: var(--primary);
        padding: 1rem 1.5rem;
    }

</style>

<style>
	
	.table-guarantee-funds .table.b-table {
		width: 215%;
	}

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>